import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { suspicions } from '../../services/nestApiService';
import { CreateSuspicionItemBody } from '../../types/SuspicionItem';

export const useCreateSuspicionItemMutation = (): UseMutationResult<
    unknown,
    unknown,
    CreateSuspicionItemBody
> => {
    const queryClient = useQueryClient();

    return useMutation(
        async (payload: CreateSuspicionItemBody) =>
            suspicions.items.create(payload),
        {
            retry: (attempt) => attempt < 3,
            retryDelay: (attempt) => 2000 * Math.pow(2, attempt - 1),
            onSuccess: () => {
                // TODO: Explore ways to optimize revalidation and
                // determine if all follow-up queries genuinely need to be refetched.
                const invalidationKeys = [
                    'indicators',
                    'suspicions-by-category',
                    'all-suspicion-labels',
                    'suspicion-articles',
                    'elastic-search',
                ];
                queryClient.invalidateQueries({
                    predicate: (query) =>
                        invalidationKeys.some((key) =>
                            query.queryKey.includes(key),
                        ),
                });
            },
        },
    );
};
