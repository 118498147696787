import { FC, FormEvent, useCallback } from 'react';
import { MdClose } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { debounce } from 'lodash';
import { Input } from '_atoms';

export type SearchPanelProps = {
    onSearchChange: (value: string) => void;
    searchValue?: string;
    placeholder?: string;
    className?: string;
};

export const SearchPanel: FC<SearchPanelProps> = ({
    onSearchChange,
    placeholder,
    searchValue = '',
    className = '',
    children,
}) => {
    const { t } = useTranslation();

    // maybe it's because I'm tired, but I couldn't get this to work with any workarounds to the
    // exhaustive deps rule issues, so please forgive me and feel free to "fix" this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedUpdate = useCallback(debounce(onSearchChange, 750), [
        onSearchChange,
    ]);

    const hasActiveQuery = searchValue.length > 0;

    const clearQuery = () => onSearchChange('');

    const handleQueryChange = (e: FormEvent<HTMLInputElement>) => {
        const value = e.currentTarget?.value;
        if (typeof value === 'string') {
            debouncedUpdate(value);
        }
    };

    return (
        <div className={classnames('relative', className)}>
            <div
                className={classnames(
                    'flex relative justify-center items-center',
                    className,
                )}
            >
                <Input
                    type="text"
                    wrapperClassName="grow relative"
                    placeholder={placeholder || t('searchAndFilter')}
                    defaultValue={searchValue}
                    onInput={handleQueryChange}
                >
                    {searchValue.length > 0 && (
                        <button
                            className="absolute right-0 p-3 cursor-pointer outline-none top-1/2 -translate-y-1/2 text-neutral-500 hover:text-primary-4 disabled:opacity-50"
                            onClick={clearQuery}
                            disabled={!hasActiveQuery}
                            type="button"
                        >
                            <MdClose className="align-middle" />
                        </button>
                    )}
                </Input>
            </div>
            <div className="flex flex-col w-full divide-y text-sm py-3">
                {children}
            </div>
        </div>
    );
};
