import { useQuery, UseQueryResult } from 'react-query';
import { targets } from '../../services/nestApiService';
import { AllSuspicionLabels } from '_types';

export const useAllSuspicionLabelsQuery = (
    targetId: string,
    shouldRefetch?: boolean,
): UseQueryResult<AllSuspicionLabels> =>
    useQuery(
        ['target', targetId, 'all-suspicion-labels'],
        () => targets.getAllSuspicionLabels(targetId),
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            refetchInterval: shouldRefetch ? 60000 * 3 : undefined, // refetch every three minutes
        },
    );
