import React, { ReactNode, useState } from 'react';
import { Button, Modal, Paragraph } from '_atoms';
import { useTranslation } from 'react-i18next';
import { TiDocumentText } from 'react-icons/ti';

interface MetaProps {
    meta: {
        label: string;
        list?: string[] | number[];
    }[];
    poweredBy?: ReactNode;
}

export const Meta = (props: MetaProps): React.ReactElement => {
    const { meta, poweredBy } = props;
    const [modalContent, setModalContent] =
        useState<MetaProps['meta'][number] | undefined>(undefined);

    const { t } = useTranslation();

    return (
        <>
            <div className="font-jost grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {meta.map((item, index) => (
                    <div key={index}>
                        <h3 className="font-bold mb-1 text-neutral-500">
                            {item.label}
                        </h3>
                        <div className="space-y-1 text-font-dark">
                            {item.list?.length ? (
                                item.list
                                    .slice(0, 1)
                                    .map((listItem, idx) => (
                                        <div key={idx}>{listItem}</div>
                                    ))
                            ) : (
                                <div>{t('notAvailable')}</div>
                            )}
                        </div>
                        {(item.list?.length ?? 0) > 2 && (
                            <div
                                className="text-primary-4 font-bold mt-1.5 flex items-center cursor-pointer"
                                onClick={() =>
                                    setModalContent({
                                        ...item,
                                        list: item.list?.slice(1),
                                    })
                                }
                            >
                                {t('seeXMore', {
                                    x: item.list?.slice(1).length,
                                })}
                                <TiDocumentText size={20} className="ml-1" />
                            </div>
                        )}
                    </div>
                ))}
                <Modal
                    isOpen={Boolean(modalContent)}
                    title={modalContent?.label ?? ''}
                    onClose={() => setModalContent(undefined)}
                    maxWidth="max-w-4xl"
                    className="text-center"
                >
                    <div className="text-left max-h-[75vh] overflow-y-auto">
                        {modalContent?.list?.map((content, index) => (
                            <Paragraph
                                key={index}
                                className="even:bg-neutral-200 p-2 rounded-md hyphens-auto break-words"
                            >
                                {content}
                            </Paragraph>
                        ))}
                    </div>
                    <div className="text-center">
                        <Button
                            level="primaryGhost"
                            onClick={() => setModalContent(undefined)}
                            className="min-w-48"
                        >
                            {t('closeButton')}
                        </Button>
                    </div>
                </Modal>
                {poweredBy}
            </div>
        </>
    );
};
