import React, { FC, ReactElement } from 'react';
import { Press } from './Press';
import { SuspicionItemEntityType } from '_enums';
import { SearchEngine } from './SearchEngine';
import { Company } from './Company';
import { SocialMediaPost } from './SocialMediaPost';
import {
    ArticleEntityWithSuspicions,
    ArticleIndicator,
} from '../../../../../types/SuspicionsByCategory';

interface ArticlePreviewProps {
    isActive: boolean;
    onClick: () => void;
    article: ArticleEntityWithSuspicions;
    indicators: ArticleIndicator[];
    onSuspicionClick?: () => void;
    isSuspicionSidebarCollapsed?: boolean;
}

export const ArticlePreview: FC<ArticlePreviewProps> = ({
    article,
    indicators,
    isActive,
    onClick,
    onSuspicionClick,
    isSuspicionSidebarCollapsed,
}): ReactElement | null => {
    const suspicionCount = article.suspicionItems?.length ?? 0;

    const buildProps = <T,>(article: T) => ({
        article,
        indicators,
        suspicionCount,
        isActive,
        onClick,
        onSuspicionClick:
            suspicionCount && isSuspicionSidebarCollapsed
                ? onSuspicionClick
                : undefined,
    });

    const { value } = article;

    if (value.type === SuspicionItemEntityType.Press) {
        return <Press {...buildProps(value)} />;
    }

    if (value.type === SuspicionItemEntityType.GoogleResult) {
        return <SearchEngine {...buildProps(value)} />;
    }

    if (value.type === SuspicionItemEntityType.TargetCompany) {
        return <Company {...buildProps(value)} />;
    }

    if (value.type === SuspicionItemEntityType.SocialMediaPost) {
        return <SocialMediaPost {...buildProps(value)} />;
    }

    return null;
};
