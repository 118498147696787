import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { SuspicionItemStatus } from '_enums';
import { suspicions } from '../../services/nestApiService';

type Payload = {
    itemIds: string[];
    status: SuspicionItemStatus;
};

export const useUpdateSuspicionItemsMutation = (): UseMutationResult<
    unknown,
    unknown,
    Payload
> => {
    const queryClient = useQueryClient();

    return useMutation(
        async ({ itemIds, status }: Payload) =>
            suspicions.items.updateMany(itemIds, status),
        {
            retry: (attempt) => attempt < 3,
            retryDelay: (attempt) => 2000 * Math.pow(2, attempt - 1),
            onSuccess: () => {
                const invalidationKeys = [
                    'indicators',
                    'suspicions-by-category',
                    'all-suspicion-labels',
                    'suspicion-articles',
                    'elastic-search',
                ];
                queryClient.invalidateQueries({
                    predicate: (query) =>
                        invalidationKeys.some((key) =>
                            query.queryKey.includes(key),
                        ),
                });
            },
        },
    );
};
