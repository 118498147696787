import classNames from 'classnames';
import React, { forwardRef } from 'react';

export const TextArea = forwardRef<
    HTMLTextAreaElement,
    React.ComponentProps<'textarea'>
>(({ className, ...props }, ref) => {
    return (
        <textarea
            className={classNames(
                `border-1 border-primary-4 p-2 rounded-lg w-full`,
                'active:outline-0 active:ring-0 focus:outline-0 focus:ring-0',
                'disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-neutral-200',
                className,
            )}
            {...props}
            ref={ref}
        />
    );
});

TextArea.displayName = 'TextArea';
