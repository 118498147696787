import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { targets } from '../../services/nestApiService';
import { SuspicionArticle } from '_types';
import { Paging } from '../../services/dataService';
import { SuspicionFilters } from '../../features/dashboard/RiskAnalysisV2/useFilters';

export type SuspicionArticleFilters = Pick<
    SuspicionFilters,
    'contentTypes' | 'reviewStatuses' | 'risk' | 'sources'
> & {
    targetId: string;
    suspicionId?: string;
};

export const useSuspicionArticlesQuery = (
    filters: SuspicionArticleFilters,
    pageSize = 4,
): UseInfiniteQueryResult<{ data: SuspicionArticle[]; paging: Paging }> => {
    return useInfiniteQuery(
        [
            'target',
            filters.targetId,
            filters.suspicionId,
            filters.risk,
            filters.contentTypes?.sort().join(','),
            filters.reviewStatuses?.sort().join(','),
            filters.sources?.sort().join(','),
            'suspicion-articles',
        ],
        ({ pageParam = 1 }) =>
            targets.getSuspicionArticles(filters, {
                pageSize,
                page: pageParam,
            }),
        {
            getNextPageParam: (lastPage) => {
                const { paging } = lastPage;
                return paging.nextPage ? paging.currentPage + 1 : undefined;
            },
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            enabled: !!filters.suspicionId && !!filters.risk,
        },
    );
};
