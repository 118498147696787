import React, { useState } from 'react';
import { SuspicionArticle } from '_types';
import { OverlayWithSpinner } from '_atoms';
import { ManageSuspicions } from './ManageSuspicions';
import { ArticlePreview } from './ArticlePreview';
import { ArticleContent } from './ArticleContent';
import classNames from 'classnames';
import { InfiniteScrollV2 } from '_molecules';
import { Paging } from '../../../../services/dataService';
import { HighlightChunks } from '../../../../components/_atoms/Highlight/types';

interface SuspicionDetailsProps {
    suspicionId?: string;
    articles: SuspicionArticle[];
    selectedArticle?: SuspicionArticle;
    selectArticle: (id: string) => void;
    isLoading?: boolean;
    pagination?: Paging & {
        handlePageChange: (nextPage: number) => void;
    };
    isNextPageLoading: boolean;
    highlightChunks?: HighlightChunks;
}

export const SuspicionDetails = (
    props: SuspicionDetailsProps,
): React.ReactElement => {
    const {
        articles,
        isLoading,
        selectedArticle,
        selectArticle,
        pagination,
        isNextPageLoading,
        suspicionId,
        highlightChunks,
    } = props;

    const parentScrollClass =
        'max-h-inside-content-height h-inside-content-height';

    const childScrollClass = `overflow-y-auto ${parentScrollClass}`;

    const [isSuspicionSidebarCollapsed, setIsSuspicionSidebarCollapsed] =
        useState(false);

    return (
        <OverlayWithSpinner
            showOverlay={!!isLoading}
            className={classNames(parentScrollClass, 'pt-3')}
        >
            <div className="grid grid-cols-3 gap-6">
                <div
                    className={classNames(
                        'col-span-1 space-y-4',
                        childScrollClass,
                    )}
                >
                    {articles.map((article) => (
                        <ArticlePreview
                            key={article.entity.value.id}
                            article={article.entity}
                            indicators={article.indicators}
                            onClick={() => {
                                selectArticle(article.entity.value.id);
                            }}
                            onSuspicionClick={() =>
                                setIsSuspicionSidebarCollapsed(false)
                            }
                            isActive={
                                selectedArticle?.entity.value.id ===
                                article.entity.value.id
                            }
                            isSuspicionSidebarCollapsed={
                                isSuspicionSidebarCollapsed
                            }
                        />
                    ))}
                    {pagination && (
                        <InfiniteScrollV2
                            paging={pagination}
                            isLoading={isNextPageLoading}
                            setPageNumber={pagination.handlePageChange}
                        />
                    )}
                </div>
                <div
                    className={classNames(
                        'col-span-2 flex gap-6',
                        childScrollClass,
                    )}
                >
                    <div className="w-full overflow-y-auto h-full">
                        {selectedArticle && (
                            <ArticleContent
                                article={selectedArticle.entity}
                                highlightChunks={highlightChunks}
                            />
                        )}
                    </div>
                    <div
                        className={classNames(
                            'relative transition-all duration-300',
                            !isSuspicionSidebarCollapsed
                                ? 'w-2/3'
                                : 'w-14 shrink-0',
                            childScrollClass,
                        )}
                    >
                        <ManageSuspicions
                            selectedArticle={selectedArticle}
                            activeSuspicionId={suspicionId}
                            collapsed={isSuspicionSidebarCollapsed}
                            onCollapse={() =>
                                setIsSuspicionSidebarCollapsed((prev) => !prev)
                            }
                        />
                    </div>
                </div>
            </div>
        </OverlayWithSpinner>
    );
};
