import { useState } from 'react';

type UseRawFiltersReturnType<T> = {
    getFilter: <K extends keyof T>(key: K) => T[K] | undefined;
    onFilterChange: <K extends keyof T>(name: K, value?: T[K]) => void;
};

export const useRawFilters = <
    T extends Record<string, unknown>,
>(): UseRawFiltersReturnType<T> => {
    const [filters, setFilters] = useState<ReadonlyMap<keyof T, T[keyof T]>>(
        new Map(),
    );

    const getFilter = <K extends keyof T>(key: K): T[K] | undefined => {
        return filters.get(key) as T[K] | undefined;
    };

    return {
        getFilter,
        onFilterChange: <K extends keyof T>(name: K, value?: T[K]) =>
            setFilters((previousMap) => {
                const map = new Map(previousMap);

                if (value !== undefined) {
                    map.set(name, value);
                } else {
                    map.delete(name);
                }

                return map;
            }),
    };
};
