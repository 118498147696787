import {
    buildSearchUrl,
    EntityTypePlusAllText,
} from './queries/useSearchQuery';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import axios from 'axios';
import { AllDataArticle } from '../services/searchService';
import { targets } from '../services/nestApiService';
import { SuspicionArticle } from '_types';

type SearchFilter = {
    query: string;
    type: string;
    value: unknown;
};

type SearchParams = {
    caseId: string;
    targetId: string;
    entityType?: EntityTypePlusAllText;
    pageSize?: number;
    exactMatch?: boolean;
    query?: string;
};

type ElasticSearchQueryProps = {
    body?: Record<string, SearchFilter>;
    params: SearchParams;
};

type PaginationMeta = {
    count?: Record<string, any>;
    paging: {
        count: number;
        currentPage: number;
        nextPage: number;
        pagesCount: number;
    };
};

type ElasticSearchOutput = PaginationMeta & {
    items: AllDataArticle[];
};

type ElasticSearchWithSuspicionsQueryOutput = PaginationMeta & {
    items: SuspicionArticle[];
};

const getSortedValuesByKey = (obj: Record<string, unknown>): string =>
    JSON.stringify(
        Object.keys(obj)
            .sort()
            .map((key) => obj[key as keyof SearchParams]),
    );

export const useElasticSearchQuery = (
    props: ElasticSearchQueryProps,
): UseInfiniteQueryResult<ElasticSearchWithSuspicionsQueryOutput> => {
    const { params, body } = props;

    return useInfiniteQuery<ElasticSearchWithSuspicionsQueryOutput>(
        [
            'elastic-search',
            getSortedValuesByKey(params),
            getSortedValuesByKey(body ?? {}),
        ],
        async ({ pageParam = 1 }) => {
            const url = buildSearchUrl({
                caseId: params.caseId,
                targetId: params.targetId,
                page: pageParam,
                type: params.entityType,
                pageSize: params.pageSize ?? 12,
                exactMatch: params.exactMatch ? 1 : 0,
                query: params.query,
            });

            const elasticData = await axios
                .post(url, body ?? {})
                .then((response) => response.data as ElasticSearchOutput);

            const entityIds = elasticData.items.map((item) => item.id);

            if (!entityIds.length) {
                return {
                    ...elasticData,
                    items: elasticData.items.map((item) => ({
                        entity: {
                            value: item,
                        },
                        indicators: [],
                    })),
                };
            }

            const articleSuspicions = await targets.getArticleSuspicions(
                params.targetId,
                entityIds,
            );

            return {
                ...elasticData,
                items: elasticData.items.map((item) => ({
                    entity: {
                        value: item,
                        suspicionItems: articleSuspicions[item.id],
                    },
                    indicators: [],
                })),
            };
        },
        {
            getNextPageParam: (lastPage) => {
                const { paging } = lastPage;
                return paging.nextPage ? paging.currentPage + 1 : undefined;
            },
            refetchOnWindowFocus: false,
            enabled: true,
            keepPreviousData: true,
        },
    );
};

export const toElasticQuery = (value: unknown): SearchFilter => ({
    query: 'terms',
    type: 'query',
    value,
});

export const toElasticFilter = (value: unknown): SearchFilter => ({
    query: 'range',
    type: 'filter',
    value,
});
