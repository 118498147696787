import { useQuery, UseQueryResult } from 'react-query';
import { SupportedSuspicion } from '_types';
import { suspicions } from '../../services/nestApiService';

export const useSupportedSuspicionsQuery = (
    caseId: string,
): UseQueryResult<SupportedSuspicion[]> => {
    return useQuery(
        ['supported-suspicions', caseId],
        () => suspicions.listSupportedSuspicions(caseId),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        },
    );
};
