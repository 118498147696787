import React, { FC, useState } from 'react';
import { ResultsProps } from '../../../Results';
import { ProfileInfo } from '../../../RiskAnalysisV2/ProfileInfo';
import { DefaultPageLayout } from '../../../../../components/Layout/DefaultPageLayout';
import { useTranslation } from 'react-i18next';
import {
    Checkbox,
    Paragraph,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import {
    Experience,
    ExperienceType,
    NoTimeExperience,
    TimelineFilters,
} from '../types';
import { DatePickerV2 } from '../../../../../components/_atoms/DatePicker/DatePickerV2';
import { SearchBar } from '../../../../../components/_molecules/SearchBar/SearchBar';
import { deduplicateExperiences, parseToDate } from '../CvStatistics/helpers';
import { Target } from '../../../../../services/dataService';
import { ALL_SOURCES, handleSourceChange } from '../helpers';
import { TargetReportSourceType } from '@indicium/common';
import { HorizontalTimeline } from './Components/HorizontalTimeline';
import { NoTimeframeExperience } from './Components/NoTimeframeExperience';
import { HeaderMessage } from '../CvStatistics/Components/HelperComponents';
import { HiMiniQuestionMarkCircle } from 'react-icons/hi2';

const calculateRows = (filteredData: Experience[]) => {
    const rows: Experience[][] = [];
    filteredData
        .sort((a, b) => {
            const startDiff = a.start.getTime() - b.start.getTime();
            if (startDiff !== 0) return startDiff;

            // Compare end dates if start dates are the same
            const endDiff = a.end.getTime() - b.end.getTime();
            if (endDiff !== 0) return endDiff;

            // Compare headers if start and end dates are the same
            const headerDiff = a.header.localeCompare(b.header);
            if (headerDiff !== 0) return headerDiff;

            // Compare subHeaders if all above are the same
            return a.subHeader.localeCompare(b.subHeader);
        })
        .forEach((experience) => {
            let placed = false;
            for (const row of rows) {
                if (experience.start >= row[row.length - 1].end) {
                    row.push(experience);
                    placed = true;
                    break;
                }
            }

            if (!placed) {
                rows.push([experience]);
            }
        });

    return rows;
    // TODO: ask for clarification on this
    // return rows.reverse();
};

const SectionHeader: FC<{ title: string }> = ({ title }) => (
    <div className="text-primary-1 font-bold">{title}</div>
);

const mapDataToExperience = (targetData: Target | undefined): Experience[] => {
    if (!targetData) {
        return [];
    }

    const targetCreationDate = targetData?.createdAt
        ? parseToDate(targetData.createdAt)
        : undefined;

    const workExperience = (targetData?.companies ?? [])
        .map(({ data }) => {
            return data.role.map((workExperience) => ({
                header: data.name.value,
                subHeader: workExperience.value.role ?? '',
                start: parseToDate(workExperience.value.from),
                end: parseToDate(workExperience.value.to ?? targetCreationDate),
                type: 'work',
                sources: workExperience.sources,
            }));
        })
        .flat()
        .filter((e): e is Experience => e.start !== null && e.end !== null);

    const education = (targetData?.profile?.data.educations ?? [])
        .map((education) => {
            return {
                header: education.value.school ?? '',
                subHeader: education.value.degree ?? '',
                start: parseToDate(education.value.date_range?.start),
                end: parseToDate(
                    education.value.date_range?.end ?? targetCreationDate,
                ),
                type: 'education',
                sources: education.sources,
            };
        })
        .filter((e): e is Experience => e.start !== null && e.end !== null);

    return [
        ...deduplicateExperiences(workExperience),
        ...deduplicateExperiences(education),
    ];
};

const groupNoTimeframeData = (
    data: { header: string; subHeader: string }[],
    type: ExperienceType,
): NoTimeExperience[] => {
    const groupedData: NoTimeExperience[] = [];

    const header = new Set<string>(data.map((e) => e.header));

    header.forEach((header) => {
        const subheader = data
            .filter((e) => e.header === header)
            .map((e) => e.subHeader)
            .flat()
            .filter((e) => e.length > 0);

        groupedData.push({
            header,
            subHeader: subheader,
            type,
        });
    });
    return groupedData;
};

const mapNoTimeframeData = (
    targetData: Target | undefined,
): NoTimeExperience[] => {
    if (!targetData) {
        return [];
    }

    const workExperience = Object.entries(
        targetData?.entrepreneurialActivities ?? {},
    )
        .flatMap(([_, workExperience]) => {
            return workExperience;
        })
        .flat()
        .filter((e) => e.from === null)
        .map((workExperience) => {
            return {
                header: workExperience.companyName,
                subHeader: workExperience.role ?? '',
            };
        });

    const education = (targetData?.profile?.data.educations ?? [])
        .filter((e) => !e.value.date_range || e.value.date_range.start === null)
        .map((education) => {
            return {
                header: education.value.school ?? '',
                subHeader: education.value.degree ?? '',
            };
        });

    return [
        ...groupNoTimeframeData(workExperience, 'work'),
        ...groupNoTimeframeData(education, 'education'),
    ];
};

const getDateRanges = (data: Experience[]) => ({
    minDate: data.length
        ? new Date(Math.min(...data.map((d) => d.start.getTime())))
        : new Date(),
    maxDate: data.length
        ? new Date(Math.max(...data.map((d) => d.end.getTime())))
        : new Date(),
});

export const CvTimelinePage: FC<ResultsProps> = ({
    targetData,
    targetImages,
}) => {
    const { t } = useTranslation();

    const targetFullName = [
        targetData?.firstname,
        targetData?.middlename,
        targetData?.lastname,
    ]
        .filter(Boolean)
        .join(' ');

    const mappedData = mapDataToExperience(targetData);
    const noTimeframeData = mapNoTimeframeData(targetData);

    const originalRanges = getDateRanges(mappedData);

    const [ranges, setRanges] =
        useState<{ minDate: Date; maxDate: Date }>(originalRanges);

    const [filteredData, setFilteredData] = useState<Experience[]>(mappedData);

    const [filters, setFilters] = useState<TimelineFilters>({
        startDate: ranges.minDate,
        endDate: ranges.maxDate,
        header: '',
        showGaps: false,
        sources: ALL_SOURCES,
    });

    const [rows, setRows] = useState<Experience[][]>(
        calculateRows(filteredData),
    );

    const handleFilterChange = (name: keyof TimelineFilters, value: any) => {
        setFilters((prev) => ({ ...prev, [name]: value }));

        const newFilters = { ...filters, [name]: value };
        const { startDate, endDate, header, sources } = newFilters;

        const filteredData = mappedData.filter((d) => {
            const matchesHeader =
                header.length === 0 ||
                d.header.toLowerCase().includes(header.toLowerCase());

            const matchesDate =
                (!startDate || d.start >= new Date(startDate)) &&
                (!endDate || d.start <= new Date(endDate));

            const matchesSource = d.sources.some((s) => sources.includes(s));

            return matchesDate && matchesHeader && matchesSource;
        });

        const newRange = getDateRanges(filteredData);
        setRanges({
            minDate:
                newFilters.header.length === 0
                    ? newFilters.startDate ?? originalRanges.minDate
                    : newRange.minDate,
            maxDate:
                newFilters.header.length === 0
                    ? newFilters.endDate ?? originalRanges.maxDate
                    : newRange.maxDate,
        });

        setFilteredData(filteredData);
        setRows(calculateRows(filteredData));
    };

    const changeSource = (value: TargetReportSourceType[]) => {
        handleFilterChange('sources', value);
    };

    return (
        <DefaultPageLayout
            title={t('cVTimeline.title')}
            headerActions={
                <ProfileInfo
                    name={targetFullName}
                    createdAt={targetData?.profile?.createdAt}
                    imageUrl={targetImages[0]}
                />
            }
        >
            <div className="flex flex-col gap-6">
                <HeaderMessage />
                <div className="flex flex-col gap-4 pt-1">
                    <SectionHeader title={t('cVAnalysis.timeline')} />
                    <div className="flex flex-row gap-4 justify-between">
                        <div className="justify-start">
                            <Paragraph
                                color="light"
                                weight="bold"
                                size="small"
                                className="font-jost"
                            >
                                {t('cVAnalysis.filters.selectSourceGroup')}
                            </Paragraph>
                            <div className="flex gap-4 py-3.5">
                                <Checkbox
                                    onChange={(isSelected) =>
                                        handleSourceChange(
                                            filters.sources,
                                            'userInput',
                                            isSelected,
                                            changeSource,
                                        )
                                    }
                                    initialIsSelected={filters.sources.includes(
                                        'userInput',
                                    )}
                                    centered={true}
                                    className="font-normal"
                                    size={5}
                                    label={t(
                                        `cVAnalysis.filters.sourceGroupOptions.cv`,
                                    )}
                                    labelPosition="append"
                                    labelClassName="text-base"
                                />
                                <Checkbox
                                    onChange={(isSelected) =>
                                        handleSourceChange(
                                            filters.sources,
                                            'osint',
                                            isSelected,
                                            changeSource,
                                        )
                                    }
                                    initialIsSelected={filters.sources.includes(
                                        'orbis',
                                    )}
                                    centered={true}
                                    className="text-neutral-500 font-normal"
                                    size={5}
                                    label={t(
                                        `cVAnalysis.filters.sourceGroupOptions.osint`,
                                    )}
                                    labelPosition="append"
                                    labelClassName="text-base"
                                />
                            </div>
                        </div>
                        <div className="flex gap-1">
                            <DatePickerV2
                                dateFormat="dd MMM yyyy"
                                selected={ranges.minDate}
                                onChange={(from) => {
                                    handleFilterChange(
                                        'startDate',
                                        from ?? originalRanges.minDate,
                                    );
                                }}
                                label={t('cVAnalysis.filters.startDate')}
                                minDate={originalRanges.minDate}
                                maxDate={originalRanges.maxDate}
                                isClearable
                            />
                            <DatePickerV2
                                dateFormat="dd MMM yyyy"
                                selected={ranges.maxDate}
                                onChange={(to) => {
                                    handleFilterChange(
                                        'endDate',
                                        to ?? originalRanges.maxDate,
                                    );
                                }}
                                label={t('cVAnalysis.filters.endDate')}
                                minDate={originalRanges.minDate}
                                maxDate={originalRanges.maxDate}
                                isClearable
                            />
                        </div>
                        <div className="flex-grow">
                            <Paragraph
                                color="light"
                                weight="bold"
                                size="small"
                                className="font-jost"
                            >
                                {t('cVAnalysis.filters.search')}
                            </Paragraph>
                            <SearchBar
                                query={filters.header}
                                onSearchChange={({ query }) => {
                                    handleFilterChange('header', query);
                                }}
                                placeholder={t(
                                    'cVAnalysis.filters.searchPlaceholder',
                                )}
                                className="w-80"
                            />
                        </div>
                    </div>
                    <HorizontalTimeline
                        data={filteredData}
                        filters={filters}
                        rows={rows}
                        range={ranges}
                    />
                </div>
                {noTimeframeData.length > 0 && (
                    <div className="flex flex-col gap-4">
                        <div className="flex items-center gap-2">
                            <SectionHeader
                                title={t('cVAnalysis.noTimeframe')}
                            />
                            <TooltipV2 placement="right" withArrow={true}>
                                <TooltipTriggerV2 className="text-neutral-400 hover:text-primary-1">
                                    <HiMiniQuestionMarkCircle className="w-5 h-5" />
                                </TooltipTriggerV2>
                                <TooltipContentV2>
                                    {t('cVAnalysis.noTimeframeTooltip')}
                                </TooltipContentV2>
                            </TooltipV2>
                        </div>

                        <NoTimeframeExperience data={noTimeframeData} />
                    </div>
                )}
            </div>
        </DefaultPageLayout>
    );
};
