import { Paragraph, SelectV2 } from '_atoms';
import React from 'react';

type SelectProps = {
    options: { label: string; value?: string }[];
    value?: string;
    onChange: (value: string) => void;
    title?: string;
};

export const Select = (props: SelectProps): React.ReactElement => {
    const { value, onChange, options, title } = props;

    const selectOptions = options.map((option, index) => ({
        ...option,
        id: index,
    }));

    return (
        <div className="py-2">
            {title && (
                <Paragraph
                    color="light"
                    size="small"
                    className="uppercase mb-1"
                >
                    {title}
                </Paragraph>
            )}
            <SelectV2
                options={selectOptions}
                selected={options.find((option) => option.value === value)}
                className="w-1/4"
                onChange={onChange}
            />
        </div>
    );
};
