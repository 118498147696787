import React, { useMemo } from 'react';
import { PressArticle } from '../../../../../services/searchService';
import { FullText } from './components/FullText';
import { Meta } from './components/Meta';
import { Divider } from './components/Divider';
import { formatDate } from '_utils';
import { useTranslation } from 'react-i18next';

import { SharedArticleContentProps } from './types';

interface PressProps extends SharedArticleContentProps {
    article: PressArticle;
}

const getSource = (article: PressArticle, t: (key: string) => string) => {
    const subtype = article.subtype ? ` (${t(article.subtype)})` : '';

    if (article.print?.publicationEdition) {
        return [`${article.print.publicationEdition}${subtype}`];
    }

    if (article.source) {
        return [`${article.source.name}${subtype}`];
    }

    return undefined;
};

export const Press = (props: PressProps): React.ReactElement => {
    const { article, highlightChunks, tooltips } = props;
    const { t } = useTranslation();

    const highlight = {
        ...highlightChunks,
        caseKeywords: article.matchingKeywords?.map(({ name }) => name),
    };

    const meta = useMemo(
        () => [
            {
                label: t('riskAnalysis.articleDetails.source'),
                list: getSource(article, t),
            },
            {
                label: t('riskAnalysis.articleDetails.pubDate'),
                list: article.estimatedPublishedDate
                    ? [formatDate(article.estimatedPublishedDate)]
                    : article.createdDate
                    ? [formatDate(article.createdDate)]
                    : undefined,
            },
            {
                label: t('riskAnalysis.articleDetails.author'),
                list: article.author?.name ? [article.author.name] : undefined,
            },
        ],
        [article, t],
    );

    return (
        <>
            <Meta
                meta={meta}
                poweredBy={
                    !article.subtype || article.subtype === 'lexisNexis' ? (
                        <img
                            alt="LexisNexis Logo"
                            className="w-25"
                            src={`${process.env.PUBLIC_URL}/lexisnexis-poweredby.png`}
                        />
                    ) : undefined
                }
            />
            <Divider className="my-5" />
            <FullText
                title={article.title}
                text={article.content}
                highlight={highlight}
                tooltips={tooltips}
            />
        </>
    );
};
