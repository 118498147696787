import * as Yup from 'yup';
import {
    DateRange as FormSchemaDateRange,
    DynamicKeyword,
    FormSchema,
} from './form.interface';
import {
    ContactType,
    DateRange,
    DynamicFormValue,
    facebookIdRegExp,
    instagramIdRegExp,
    linkedinIdRegExp,
    SocialMediaType,
    Title,
    twitterIdRegExp,
    UserInputTarget,
    xingIdRegExp,
} from '@indicium/common';
import { uniqueId } from 'lodash';
import { ProcessTarget } from '@indicium/common/src/types/Target/TargetTypes';
import moment, { unitOfTime } from 'moment/moment';
import { toDateStr } from '@indicium/common/src/types/Date/DateStr';
import { generateDateRangeString } from '../../TargetForm/targetFormUtils';
import { DynamicFormValueStatus } from '@indicium/common/src/types/DynamicForm/DynamicForm';
import { TargetFormSchema } from '../../../../schemas/targetFormSchema';

const validateDate = (dateString: string) => {
    const format = ['DD.MM.YYYY', 'YYYY'];
    const date = moment(dateString, format, false);
    if (!date.isValid()) {
        return '';
    }
    const timeUnit: unitOfTime.StartOf =
        dateString.length === 4 ? 'year' : 'day';

    if (timeUnit === 'year') return dateString;

    return date.format('DD.MM.YYYY');
};

const extractDateRangeObjForFormSchema = (
    dateRange: DateRange | string | undefined,
): FormSchemaDateRange => {
    try {
        const result: FormSchemaDateRange = {
            start: '',
            end: '',
        };
        if (typeof dateRange === 'object') {
            result.start = validateDate(dateRange.start);
            result.end = validateDate(dateRange.end);
            return result;
        }
        if (typeof dateRange === 'string' && dateRange) {
            const dates = dateRange.split('-').map((date) => date.trim());
            result.start = validateDate(dates[0]);
            if (dates.length === 2) {
                result.end = validateDate(dates[1]);
            }
        }
        return result;
    } catch (error) {
        console.log('Error while extracting dates', error);
        return {
            start: '',
            end: '',
        };
    }
};

const getDateOfBirth = (
    dateOfBirth: string | DateRange | undefined,
): string => {
    if (dateOfBirth === undefined) return '';
    if (typeof dateOfBirth === 'string') return dateOfBirth;
    if (typeof dateOfBirth === 'object') {
        return dateOfBirth.start ?? '';
    }
    return '';
};

const mapUserInputTargetDynamicKeywordsToFormSchema = (
    keywords?: DynamicFormValue[] | null,
): DynamicKeyword[] =>
    (keywords ?? []).map((keyword) => ({
        id: keyword.id,
        value: keyword.value,
        included: keyword.status === DynamicFormValueStatus.Confirmed,
    }));

const getValidEntries = <T>(
    entries: T[],
    schema: Yup.ObjectSchema<any> | undefined,
): T[] => {
    if (!schema) return entries;
    const validEntries: T[] = [];
    for (const entry of entries) {
        try {
            schema.validateSync(entry);
            validEntries.push(entry);
        } catch (err) {
            console.error('Validation error:', err, 'Invalid entry:', entry);
        }
    }
    return validEntries;
};

const getValidEntry = <T>(
    entry: T,
    schema: Yup.StringSchema<any> | undefined,
): T | undefined => {
    if (!schema) return entry;
    try {
        schema.validateSync(entry);
        return entry;
    } catch (err) {
        console.error('Validation error:', err, 'Invalid entry:', entry);
        return undefined;
    }
};

const mapUserInputPersonTitleToFormSchema = (
    title?: string,
): Title | undefined => {
    if (!title) return undefined;

    const titleEnumValues = Object.values(Title) as string[];

    if (!titleEnumValues.includes(title)) {
        return undefined;
    }

    return title as Title;
};

// TODO: confirm images are redundant when not coming from PDF upload
export const mapUserInputTargetToFormSchema = (
    input: ProcessTarget,
): FormSchema => {
    const formSchema: FormSchema = {
        personalDetails: {
            firstName: input.firstname,
            lastName: input.lastname,
            middleName: input.middlename,
            title: mapUserInputPersonTitleToFormSchema(input.title),
            gender: input.gender,
        },
        birthInfo: {
            dateOfBirth: '',
            placeOfBirth: input.placeOfBirth,
            countryOfBirth: input.countryOfBirth,
        },

        residentialInfo: { countryOfResidence: input.countryOfResidence },
        nationalities: (input.nationalities ?? [])
            ?.filter(Boolean)
            .map((item) => ({ nationality: item })),
        contactEmails: [],
        contactPhones: [],
        contactWebsites: [],
        jobs: [],
        educationInfo: [],

        facebookLinks: [],
        instagramLinks: [],
        linkedinLinks: [],
        twitterLinks: [],
        xingLinks: [],

        keywords: mapUserInputTargetDynamicKeywordsToFormSchema([
            ...(input.websites ?? []),
            ...(input.relatedPersons ?? []),
            ...(input.organizations ?? []),
            ...(input.topics ?? []),
            ...(input.locations ?? []),
            //TODO: push input.nickname into nicknames if it exists
            ...(input.nicknames ?? []),
        ]),
        note: { value: input.note?.value },
    };

    if (input.dateOfBirth) {
        const dateOfBirthSchema = TargetFormSchema.fields.dateOfBirth;
        formSchema.birthInfo.dateOfBirth = getValidEntry(
            getDateOfBirth(input.dateOfBirth),
            dateOfBirthSchema,
        );
    }

    // Map contact
    const contactSchema = TargetFormSchema.fields.contact.innerType;
    const validContacts = getValidEntries(
        input.contact?.filter(({ value }) => Boolean(value.trim())) ?? [],
        contactSchema,
    );
    if (validContacts) {
        validContacts.forEach((contact) => {
            switch (contact.type) {
                case ContactType.email:
                    formSchema.contactEmails.push({ email: contact.value });
                    break;
                case ContactType.phone:
                    formSchema.contactPhones.push({ phone: contact.value });
                    break;
                case ContactType.website:
                    formSchema.contactWebsites.push({
                        website: contact.value,
                    });
                    break;
            }
        });
    }

    const jobsSchema = TargetFormSchema.fields.jobs.innerType;
    const validJobs = getValidEntries(input.jobs ?? [], jobsSchema);
    formSchema.jobs = validJobs.map((job) => ({
        jobTitle: job.title,
        jobDate: extractDateRangeObjForFormSchema(job.date),
        selfEmployed: job.selfEmployed,
        companyName: job.company?.name || '',
        companyWebsite: job.company?.website,
        companyVatNumber: job.company?.vatNumber,
        companyCommercialRegisterNumber: job.company?.commercialRegisterNumber,
        companyCity: job.company?.city,
    }));

    // Map education
    const educationSchema = TargetFormSchema.fields.education.innerType;
    const validEducationEntries = getValidEntries(
        input.education ?? [],
        educationSchema,
    );
    formSchema.educationInfo = validEducationEntries.map((edu) => ({
        title: edu.title,
        type: edu.type,
        date: extractDateRangeObjForFormSchema(edu.date),
        institutionName: edu.institution?.name,
        institutionLocation: edu.institution?.location,
    }));

    (input.socialMediaProfiles ?? [])
        .filter(({ value }) => Boolean(value.trim()))
        .forEach((profile) => {
            if (Object.values(SocialMediaType).includes(profile.type)) {
                switch (profile.type) {
                    case SocialMediaType.facebook:
                        formSchema.facebookLinks.push({ link: profile.value });
                        break;
                    case SocialMediaType.instagram:
                        formSchema.instagramLinks.push({
                            link: profile.value,
                        });
                        break;
                    case SocialMediaType.linkedin:
                        formSchema.linkedinLinks.push({ link: profile.value });
                        break;
                    case SocialMediaType.twitter:
                        formSchema.twitterLinks.push({ link: profile.value });
                        break;
                    case SocialMediaType.xing:
                        formSchema.xingLinks.push({ link: profile.value });
                        break;
                }
            }
        });

    return formSchema;
};

const createDateRangeObjFromFormSchema = (
    dateRange: FormSchemaDateRange | undefined,
): string | undefined => {
    return dateRange?.start && dateRange?.end
        ? generateDateRangeString({
              start: toDateStr(
                  moment(dateRange.start, ['DD.MM.YYYY', 'YYYY']).format(
                      'YYYY-MM-DD',
                  ),
              ),
              end: toDateStr(
                  moment(dateRange.end, ['DD.MM.YYYY', 'YYYY']).format(
                      'YYYY-MM-DD',
                  ),
              ),
          })
        : undefined;
};

const getSocialMediaProfiles = (
    data:
        | FormSchema['facebookLinks']
        | FormSchema['instagramLinks']
        | FormSchema['twitterLinks']
        | FormSchema['linkedinLinks']
        | FormSchema['xingLinks'],
    type: SocialMediaType,
) => {
    return data.map(({ link }) => ({
        type: type,
        value: link,
    }));
};

const mapFormSchemaDynamicKeywordsToUserInputTarget = (
    keywords?: DynamicKeyword[],
): DynamicFormValue[] =>
    (keywords ?? []).map((keyword) => ({
        id: uniqueId(),
        value: keyword.value,
        status: keyword.included
            ? DynamicFormValueStatus.Confirmed
            : DynamicFormValueStatus.Ignored,
    }));

export const mapFormSchemaToUserInputTarget = (
    formData: Partial<FormSchema>,
): UserInputTarget => {
    const { personalDetails, birthInfo } = formData;

    const userInputTarget: UserInputTarget = {
        title: personalDetails?.title,
        gender: personalDetails?.gender,
        firstname: personalDetails?.firstName ?? '',
        lastname: personalDetails?.lastName ?? '',
        middlename: personalDetails?.middleName,

        dateOfBirth: birthInfo?.dateOfBirth,
        placeOfBirth: birthInfo?.placeOfBirth,
        countryOfBirth: birthInfo?.countryOfBirth,

        countryOfResidence: formData.residentialInfo?.countryOfResidence,

        nationalities: (formData?.nationalities ?? [])
            .filter(Boolean)
            .map((item) => item.nationality),
        contact: [],
        jobs: [],
        education: [],
        socialMediaProfiles: [],

        relatedPersons: [],
        websites: [],
        organizations: [],
        topics: mapFormSchemaDynamicKeywordsToUserInputTarget(
            formData.keywords,
        ),
        locations: [],
        nicknames: [],
        note: { value: formData.note?.value },
    };

    // Map contact Data
    // TODO: DRY wins possible later?
    if (formData?.contactEmails) {
        formData?.contactEmails?.forEach(({ email }) => {
            userInputTarget.contact?.push({
                type: ContactType.email,
                value: email,
            });
        });
    }
    if (formData?.contactPhones) {
        formData?.contactPhones?.forEach(({ phone }) => {
            userInputTarget.contact?.push({
                type: ContactType.phone,
                value: phone,
            });
        });
    }
    if (formData?.contactWebsites) {
        formData?.contactWebsites?.forEach(({ website }) => {
            userInputTarget.contact?.push({
                type: ContactType.website,
                value: website,
            });
        });
    }

    // Map jobs
    userInputTarget.jobs = (formData?.jobs ?? []).map((job) => ({
        title: job.jobTitle,
        date: createDateRangeObjFromFormSchema(job?.jobDate),
        selfEmployed: job.selfEmployed,
        company: {
            name: job.companyName,
            website: job.companyWebsite,
            vatNumber: job.companyVatNumber,
            commercialRegisterNumber: job.companyCommercialRegisterNumber,
            country: job.companyCountry,
            city: job.companyCity,
        },
    }));

    // Map education
    userInputTarget.education = (formData?.educationInfo ?? []).map((edu) => {
        return {
            title: edu.title,
            type: edu.type,
            date: createDateRangeObjFromFormSchema(edu?.date),
            institution: {
                name: edu.institutionName,
                location: edu.institutionLocation,
            },
        };
    });

    userInputTarget.socialMediaProfiles?.push(
        ...getSocialMediaProfiles(
            [...(formData.facebookLinks ?? [])],
            SocialMediaType.facebook,
        ),
        ...getSocialMediaProfiles(
            [...(formData.instagramLinks ?? [])],
            SocialMediaType.instagram,
        ),
        ...getSocialMediaProfiles(
            [...(formData.linkedinLinks ?? [])],
            SocialMediaType.linkedin,
        ),
        ...getSocialMediaProfiles(
            [...(formData.twitterLinks ?? [])],
            SocialMediaType.twitter,
        ),
        ...getSocialMediaProfiles(
            [...(formData.xingLinks ?? [])],
            SocialMediaType.xing,
        ),
    );

    return userInputTarget;
};

export const fallbackUrlRegex =
    /(https?:\/\/)?(www\.)?[-\\p{L}0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-\\p{L}0-9@:%_+.~#()?&/=]*)/u;

export const getRegexForSocialMedia = (type: SocialMediaType): RegExp => {
    switch (type) {
        case SocialMediaType.facebook:
            return facebookIdRegExp;
        case SocialMediaType.instagram:
            return instagramIdRegExp;
        case SocialMediaType.linkedin:
            return linkedinIdRegExp;
        case SocialMediaType.twitter:
            return twitterIdRegExp;
        case SocialMediaType.xing:
            return xingIdRegExp;
        default:
            // in case of not handled SocialMediaType at least validate that it's a valid URL
            return fallbackUrlRegex;
    }
};
