import React, { useMemo } from 'react';
import { Button, MultiSelect } from '_atoms';
import { useTranslation } from 'react-i18next';
import { FaRegTrashAlt } from 'react-icons/fa';
import { SuspicionItemSource } from '../../../types/SuspicionItem';
import { UseFilters } from './useFilters';
import { SuspicionItemEntityType, SuspicionItemStatus } from '_enums';
import { AllSuspicionLabels } from '_types';
import { isNeitherNullNorUndefined } from '_utils';

interface SuspicionFiltersProps {
    setFilters: UseFilters['setFilters'];
    filters: UseFilters['filters'];
    allSuspicions: AllSuspicionLabels;
}

export const SuspicionFilters = (
    props: SuspicionFiltersProps,
): React.ReactElement => {
    const { t } = useTranslation();

    const { setFilters, filters, allSuspicions } = props;

    const suspicionOptions = useMemo(
        () =>
            allSuspicions
                .map((suspicion) => ({
                    id: suspicion.id,
                    label: t(`suspicionKeywords.${suspicion.label}`, {
                        defaultValue: suspicion.name,
                    }),
                    value: suspicion.id,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        [allSuspicions, t],
    );

    const sourceOptions = useMemo(
        () =>
            Object.values(SuspicionItemSource)
                .filter((source) => source !== SuspicionItemSource.Cortical)
                .map((source) => ({
                    id: source,
                    label: t(`suspicionItemSource.${source}`),
                    value: source,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        [t],
    );

    const contentTypeOptions = useMemo(
        () =>
            (
                Object.keys(SuspicionItemEntityType) as Array<
                    keyof typeof SuspicionItemEntityType
                >
            )
                .map((key) => ({
                    id: key,
                    label: t(
                        `suspicionItemEntityType.${SuspicionItemEntityType[key]}`,
                    ),
                    value: SuspicionItemEntityType[key],
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        [t],
    );

    const reviewStatusOptions = useMemo(
        () =>
            Object.values(SuspicionItemStatus)
                .map((status) => ({
                    id: status,
                    label: t(`suspicionItemStatus.${status}`),
                    value: status,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        [t],
    );

    return (
        <div className="flex justify-between items-center gap-4">
            {allSuspicions.length > 0 && (
                <>
                    <div className="flex flex-1 gap-4">
                        <div className="flex-1">
                            <MultiSelect
                                className="h-min flex-1"
                                label={t('riskAnalysis.filters.suspicion')}
                                options={suspicionOptions}
                                onChange={(suspicionIds) => {
                                    setFilters({
                                        suspicionIds: suspicionIds
                                            .map(({ value }) => value)
                                            .filter(isNeitherNullNorUndefined),
                                    });
                                }}
                                selected={suspicionOptions.filter(
                                    (suspicionOption) =>
                                        filters.suspicionIds?.includes(
                                            suspicionOption.value ?? '',
                                        ),
                                )}
                                disabled={allSuspicions.length === 0}
                            />
                        </div>
                        <div className="flex-1">
                            <MultiSelect
                                className="h-min"
                                label={t(
                                    'riskAnalysis.filters.suspicionSource',
                                )}
                                options={sourceOptions}
                                onChange={(sources) => {
                                    setFilters({
                                        sources: sources
                                            .map(
                                                ({ value }) =>
                                                    value as SuspicionItemSource,
                                            )
                                            .filter(isNeitherNullNorUndefined),
                                    });
                                }}
                                selected={sourceOptions.filter((source) =>
                                    filters.sources?.includes(
                                        source.value ?? '',
                                    ),
                                )}
                                disabled={allSuspicions.length === 0}
                            />
                        </div>
                        <div className="flex-1">
                            <MultiSelect
                                className="h-min"
                                label={t('riskAnalysis.filters.contentType')}
                                options={contentTypeOptions}
                                onChange={(contentTypes) => {
                                    setFilters({
                                        contentTypes: contentTypes
                                            .map(
                                                ({ value }) =>
                                                    value as SuspicionItemEntityType,
                                            )
                                            .filter(isNeitherNullNorUndefined),
                                    });
                                }}
                                selected={contentTypeOptions.filter(
                                    (contentType) =>
                                        filters.contentTypes?.includes(
                                            contentType.value ?? '',
                                        ),
                                )}
                                disabled={allSuspicions.length === 0}
                            />
                        </div>
                        <div className="flex-1">
                            <MultiSelect
                                className="h-min"
                                label={t('riskAnalysis.filters.reviewStatus')}
                                options={reviewStatusOptions}
                                onChange={(reviewStatuses) => {
                                    setFilters({
                                        reviewStatuses: reviewStatuses
                                            .map(
                                                ({ value }) =>
                                                    value as SuspicionItemStatus,
                                            )
                                            .filter(isNeitherNullNorUndefined),
                                    });
                                }}
                                selected={reviewStatusOptions.filter(
                                    (reviewStatus) =>
                                        filters.reviewStatuses?.includes(
                                            reviewStatus.value ?? '',
                                        ),
                                )}
                                disabled={allSuspicions.length === 0}
                            />
                        </div>
                    </div>
                    <Button
                        level="custom"
                        icon={FaRegTrashAlt}
                        className="text-primary-4 mt-4"
                        onClick={() => {
                            setFilters({
                                suspicionIds: undefined,
                                sources: undefined,
                                contentTypes: undefined,
                                reviewStatuses: undefined,
                                risk: undefined,
                                selectedSuspicionId: undefined,
                            });
                        }}
                    >
                        {t('riskAnalysis.filters.resetAll')}
                    </Button>
                </>
            )}
        </div>
    );
};
