import React from 'react';
import { Checkbox } from './Checkbox';
import { Paragraph } from '_atoms';

type OptionsListProps = {
    options: {
        value: string;
        label: string;
    }[];
    value: string[];
    onChange: (value: string[]) => void;
    title?: string;
};

export const OptionsList = (props: OptionsListProps): React.ReactElement => {
    const { options, value, onChange, title } = props;

    return (
        <div className="py-2">
            {title && (
                <Paragraph color="light" size="small" className="uppercase">
                    {title}
                </Paragraph>
            )}
            <div className="flex gap-5">
                {options.map((option, index) => (
                    <Checkbox
                        key={index}
                        label={option.label}
                        value={value.includes(option.value)}
                        onChange={(isSelected) => {
                            const newValue = isSelected
                                ? [...value, option.value]
                                : value.filter((v) => v !== option.value);

                            onChange(newValue);
                        }}
                    />
                ))}
            </div>
        </div>
    );
};
