import axios, { CancelToken } from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { UserInputTarget } from '@indicium/common';
import { backendBaseUrl } from '../../backendConfig.json';
import { Target } from '../../services/dataService';
import { getSignedInUser } from '../../services/authenticationService';
import moment from 'moment';

type Payload = {
    caseId: string;
    data: UserInputTarget;
    cvImages?: string[];
};

const convertToDateRange = (
    date: string,
): Record<'start' | 'end', string> | undefined => {
    if (date.length === 0) {
        return undefined;
    }

    if (date.length === 4) {
        // date is e.g. "2014"
        return {
            start: `${date}-01-01`,
            end: `${date}-12-31`,
        };
    }

    if (date.length === 9) {
        // date is e.g. "2014-2015"
        return {
            start: `${date.substring(0, 4)}-01-01`,
            end: `${date.substring(5, 9)}-12-31`,
        };
    }

    if (date.length === 10) {
        // date is e.g. "01.05.2021"
        const m = moment(date, 'DD.MM.YYYY', true);

        if (!m.isValid()) {
            return undefined;
        }

        const formattedDate = m.format('YYYY-MM-DD');

        return {
            start: formattedDate,
            end: formattedDate,
        };
    }

    // date is e.g. "01.05.2021-01.06.2021"
    const [date1, date2] = date.split('-');

    const formatedDate1 = moment(date1, 'DD.MM.YYYY', true);
    const formatedDate2 = moment(date2, 'DD.MM.YYYY', true);

    if (!formatedDate1.isValid() || !formatedDate2.isValid()) {
        return undefined;
    }

    return {
        start: formatedDate1.format('YYYY-MM-DD'),
        end: formatedDate2.format('YYYY-MM-DD'),
    };
};

export const createTarget = async ({
    caseId,
    data,
    cvImages,
    cancelToken,
}: Payload & { cancelToken?: CancelToken }): Promise<Target> => {
    const user = await getSignedInUser();

    const payload = Object.assign({}, data, {
        dateOfBirth: data.dateOfBirth
            ? convertToDateRange(data.dateOfBirth)
            : undefined,
        currentUserId: user.attributes.sub,
        jobs: data.jobs?.map((job) => {
            return {
                ...job,
                date:
                    typeof job.date === 'string'
                        ? convertToDateRange(job.date)
                        : job.date,
            };
        }),
        education: data.education?.map((education) => {
            return {
                ...education,
                date:
                    typeof education.date === 'string'
                        ? convertToDateRange(education.date)
                        : education.date,
            };
        }),
        cvImages,
    });

    const response = await axios.post(
        `https://${backendBaseUrl}/api/cases/${caseId}/targets`,
        payload,
        { cancelToken },
    );
    return response.data.data;
};

export const useCreateTargetMutation = (): UseMutationResult<
    Target,
    unknown,
    Payload
> => {
    const queryClient = useQueryClient();

    return useMutation(createTarget, {
        onSuccess: () => queryClient.invalidateQueries('targets'),
    });
};
