import React from 'react';
import { ArticlePreviewCard } from './components/ArticlePreviewCard';
import { PressArticle } from '../../../../../services/searchService';

import { SharedArticlePreviewProps } from './types';
import { SuspicionArticle } from '_types';

interface PressProps extends SharedArticlePreviewProps {
    article: PressArticle;
}

const getDescription = (
    article: PressArticle,
    indicators: SuspicionArticle['indicators'],
): string | null => {
    if (indicators.length > 0) {
        return indicators
            .map(({ indicator }) => `...${indicator.value}...`)
            .join('\n');
    }

    const { preview } = article;

    if (preview.length > 0) {
        return preview.join('\n');
    }

    return null;
};

export const Press = (props: PressProps): React.ReactElement => {
    const {
        onClick,
        onSuspicionClick,
        isActive,
        article,
        indicators = [],
        suspicionCount,
    } = props;

    const description = getDescription(article, indicators);

    return (
        <ArticlePreviewCard
            onClick={onClick}
            isActive={isActive}
            title={article.title}
            type="press"
            suspicionCount={suspicionCount}
            onSuspicionClick={onSuspicionClick}
        >
            {description && (
                <div className="text-sm line-clamp-3">{description}</div>
            )}
        </ArticlePreviewCard>
    );
};
