import { DatePicker, Paragraph } from '_atoms';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Range = {
    from?: Date;
    to?: Date;
};

type DateRangeProps = {
    value: Range;
    onChange: (value: Range) => void;
    title?: string;
};

export const DateRange = (props: DateRangeProps): React.ReactElement => {
    const { value, title, onChange } = props;

    const { t } = useTranslation();

    return (
        <div className="py-2">
            {title && (
                <Paragraph
                    color="light"
                    size="small"
                    className="uppercase mb-1"
                >
                    {title}
                </Paragraph>
            )}
            <div className="flex gap-5">
                <div className="date-from w-40">
                    <Paragraph
                        color="light"
                        size="small"
                        className="uppercase mb-1"
                    >
                        {t('filters.dateFrom')}
                    </Paragraph>
                    <DatePicker
                        selected={value.from}
                        isClearable
                        dateFormat="dd.MM.yyyy"
                        onChange={(from) =>
                            onChange({
                                ...value,
                                from: from ?? undefined,
                            })
                        }
                    />
                </div>
                <div className="date-to w-40">
                    <Paragraph
                        color="light"
                        size="small"
                        className="uppercase mb-1"
                    >
                        {t('filters.dateTo')}
                    </Paragraph>
                    <DatePicker
                        selected={value.to}
                        isClearable
                        dateFormat="dd.MM.yyyy"
                        onChange={(to) =>
                            onChange({
                                ...value,
                                to: to ?? undefined,
                            })
                        }
                    />
                </div>
            </div>
        </div>
    );
};
