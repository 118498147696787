import { SuspicionItemStatus } from '_enums';

export const RELEVANT_SUSPICION_STATUSES = [
    SuspicionItemStatus.Pending,
    SuspicionItemStatus.Problem,
];

export const suspicionsWithInvolvementRequired = [
    'arson',
    'business_operations_and_risk_management_offenses',
    'capital_offenses',
    'climate_change_denier',
    'conspiracy_theorist',
    'corona_denier',
    'cybercrime',
    'data_security_breaches',
    'exploiting_leading_position',
    'extremism_terror',
    'financial_and_corporate_compliance_offenses',
    'fraud_consumer',
    'hate_speech',
    'human_rights_violations',
    'insolvency_law_offenses',
    'intolerance_bigotry_discrimination',
    'labor_law_offenses',
    'left_wing_extremism',
    'negative_content',
    'organized_crime',
    'property_damage',
    'racism',
    'right_wing_extremism',
    'russian_war_supporter',
    'sex_offenses',
    'substance_abuse',
    'tax_offenses',
    'theft',
    'trade_secrets',
    'violent_crimes',
    'weapon_offenses',
];
