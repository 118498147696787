import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Error, LoadingSpinner } from '_atoms';
import { Alert } from '_molecules';
import { ResultsProps } from '../../../features/dashboard/Results';
import { nonProdDataTestId } from '_utils';
import {
    toElasticFilter,
    toElasticQuery,
    useElasticSearchQuery,
} from '../../../hooks/useElasticSearchQuery';
import * as RawSearch from '_organisms/RawSearch';
import { buildHighlightChunks } from '../../_atoms/Highlight/public-utils';
import { buildIndicatorsMap } from '../../../features/dashboard/AllDataSearch/enrichArticleWithIndicators';
import { SuspicionDetails } from '../../../features/dashboard/RiskAnalysisV2/SuspicionDetails';

type SocialMediaPostsProps = Pick<
    ResultsProps,
    'accessToken' | 'targetData' | 'caseData' | 'entityIndicators'
>;

type SearchFilters = {
    postDatePublished?: {
        from?: Date;
        to?: Date;
    };
    postSource?: string[];
    exactMatch?: boolean;
    search?: string;
};

export const SocialMediaPosts: FC<SocialMediaPostsProps> = ({
    targetData,
    caseData,
    entityIndicators,
}) => {
    const { t } = useTranslation();

    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const { getFilter, onFilterChange } =
        RawSearch.useRawFilters<SearchFilters>();

    const postDatePublished = getFilter('postDatePublished');

    const {
        data,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        isLoading,
        isFetching,
        isError,
    } = useElasticSearchQuery({
        params: {
            caseId,
            targetId,
            entityType: 'socialMediaPost',
            exactMatch: getFilter('exactMatch'),
            query: getFilter('search'),
        },
        body: {
            postDatePublished: toElasticFilter({
                gte: postDatePublished?.from,
                lte: postDatePublished?.to,
            }),
            postSource: toElasticQuery(getFilter('postSource')),
        },
    });

    const indicatorsById = useMemo(
        () => buildIndicatorsMap(entityIndicators),
        [entityIndicators],
    );

    const articles = useMemo(
        () =>
            (data?.pages.flatMap((page) => page.items) ?? []).map(
                (article) => ({
                    ...article,
                    indicators:
                        indicatorsById.get(article.entity.value.id) ?? [],
                }),
            ),
        [data, indicatorsById],
    );

    const articleMeta = data?.pages.at(-1);

    const handlePageChange = useCallback(() => {
        if (hasNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, fetchNextPage]);

    const [activeArticleId, setActiveArticleId] =
        useState<string | undefined>();

    const firstArticleId = articles?.at(0)?.entity.value.id;

    useEffect(() => {
        if (firstArticleId) {
            setActiveArticleId(firstArticleId);
        }
    }, [firstArticleId]);

    const selectedArticle = articles?.find(
        (article) => article.entity.value.id === activeArticleId,
    );

    const pagination = articleMeta?.paging
        ? {
              ...articleMeta?.paging,
              handlePageChange,
          }
        : undefined;

    const highlightChunks = buildHighlightChunks({
        searchQuery: getFilter('search'),
        target: targetData,
        caseKeywords: caseData?.keywords,
    });

    const postSourceOptions = useMemo(
        () => [
            {
                label: t('filters.Instagram'),
                value: 'instagram',
            },
            {
                label: t('filters.Facebook'),
                value: 'facebook',
            },
            {
                label: t('filters.Twitter'),
                value: 'twitter',
            },
            {
                label: t('filters.LinkedIn'),
                value: 'linkedin',
            },
            {
                label: t('filters.Xing'),
                value: 'xing',
            },
        ],
        [t],
    );

    return (
        <>
            <RawSearch.SearchPanel
                onSearchChange={(value) => onFilterChange('search', value)}
                searchValue={getFilter('search')}
            >
                <RawSearch.Checkbox
                    value={Boolean(getFilter('exactMatch'))}
                    onChange={(value) => onFilterChange('exactMatch', value)}
                    label={t('exactSearch')}
                />
                <RawSearch.DateRange
                    value={getFilter('postDatePublished') ?? {}}
                    onChange={(value) =>
                        onFilterChange('postDatePublished', value)
                    }
                />
                <RawSearch.OptionsList
                    options={postSourceOptions}
                    onChange={(value) => onFilterChange('postSource', value)}
                    value={getFilter('postSource') ?? []}
                    title={t('filters.socialMedia')}
                />
            </RawSearch.SearchPanel>
            <div
                className="relative mt-5 z-10"
                data-testid={nonProdDataTestId('socialmediaposts')}
            >
                {isLoading ? (
                    <div className="absolute top-0 flex justify-center w-full z-0">
                        <LoadingSpinner message={t('profileLoading')} />
                    </div>
                ) : isError ? (
                    <Error
                        headline={t('profileErrorHeadline')}
                        message={t('profileErrorRetryMessage')}
                    />
                ) : null}
                {articles.length ? (
                    <SuspicionDetails
                        isLoading={isFetching || isFetchingNextPage}
                        articles={articles}
                        selectedArticle={selectedArticle}
                        selectArticle={setActiveArticleId}
                        pagination={pagination}
                        isNextPageLoading={isFetchingNextPage}
                        highlightChunks={highlightChunks}
                    />
                ) : isLoading ? null : (
                    <Alert
                        alertType="info"
                        message={t('noData')}
                        className="mb-5"
                    />
                )}
            </div>
        </>
    );
};
