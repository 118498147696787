import { FC, Fragment, useCallback, useMemo } from 'react';
import { Alert, InfiniteScrollV2, WorkflowError } from '_molecules';
import { ResultsProps } from '../Results';
import { ImageItem } from './ImageItem';
import { useParams } from 'react-router-dom';
import { Error, LoadingSpinner } from '_atoms';
import { ImageArticle } from '../../../services/searchService';
import { useTranslation } from 'react-i18next';
import { nonProdDataTestId } from '_utils';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import {
    toElasticFilter,
    toElasticQuery,
    useElasticSearchQuery,
} from '../../../hooks/useElasticSearchQuery';
import * as RawSearch from '_organisms/RawSearch';

type SearchFilters = {
    createdDate?: {
        from?: Date;
        to?: Date;
    };
    imageSources?: string[];
    exactMatch?: boolean;
    search?: string;
};

export type ImagesProps = ResultsProps & { accessToken: string };

export const Images: FC<ImagesProps> = ({ targetData }: ImagesProps) => {
    const { t } = useTranslation();

    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const { getFilter, onFilterChange } =
        RawSearch.useRawFilters<SearchFilters>();

    const createdDate = getFilter('createdDate');

    const {
        data,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        isLoading,
        isError,
    } = useElasticSearchQuery({
        params: {
            caseId,
            targetId,
            entityType: 'images',
            pageSize: 36,
            exactMatch: getFilter('exactMatch'),
            query: getFilter('search'),
        },
        body: {
            createdDate: toElasticFilter({
                gte: createdDate?.from,
                lte: createdDate?.to,
            }),
            imageSources: toElasticQuery(getFilter('imageSources')),
        },
    });

    const articles = useMemo(
        () => data?.pages.flatMap((page) => page.items) ?? [],
        [data],
    );

    const articleMeta = data?.pages.at(-1);

    const handlePageChange = useCallback(() => {
        if (hasNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, fetchNextPage]);

    const imageSourceOptions = useMemo(
        () => [
            {
                label: t('filters.Facebook'),
                value: 'facebook',
            },
            {
                label: t('filters.Instagram'),
                value: 'instagram',
            },
            {
                label: t('filters.LinkedIn'),
                value: 'linkedin',
            },
            {
                label: t('filters.Xing'),
                value: 'xing',
            },
            {
                label: t('filters.Twitter'),
                value: 'twitter',
            },
            {
                label: t('filters.Google'),
                value: 'google',
            },
        ],
        [t],
    );

    return (
        <DefaultPageLayout title={t('rawData.imageSlashVideos')}>
            <RawSearch.SearchPanel
                onSearchChange={(value) => onFilterChange('search', value)}
                searchValue={getFilter('search')}
            >
                <RawSearch.Checkbox
                    value={Boolean(getFilter('exactMatch'))}
                    onChange={(value) => onFilterChange('exactMatch', value)}
                    label={t('exactSearch')}
                />
                <RawSearch.DateRange
                    value={getFilter('createdDate') ?? {}}
                    onChange={(value) => onFilterChange('createdDate', value)}
                />
                <RawSearch.OptionsList
                    options={imageSourceOptions}
                    onChange={(value) => onFilterChange('imageSources', value)}
                    value={getFilter('imageSources') ?? []}
                    title={t('filters.sources')}
                />
            </RawSearch.SearchPanel>
            <WorkflowError errors={targetData?.workflowErrors} path="profile" />
            {targetData?.status === 'HasInitialProfile' && (
                <Alert
                    alertType="warning"
                    headline={t('profileDataNotFinal')}
                    className="mb-2"
                />
            )}

            <div
                className="relative mt-5 z-10"
                data-testid={nonProdDataTestId('images')}
            >
                {isLoading ? (
                    <div className="absolute top-0 flex justify-center w-full z-0">
                        <LoadingSpinner message={t('profileLoading')} />
                    </div>
                ) : isError ? (
                    <Error
                        headline={t('profileErrorHeadline')}
                        message={t('profileErrorRetryMessage')}
                    />
                ) : null}
                {articles.length ? (
                    <Fragment>
                        <div className="grid grid-cols-2 2xl:grid-cols-6 md:grid-cols-4 gap-5 pt-5 pb-10">
                            {articles?.map((item, index) => (
                                <ImageItem
                                    key={index}
                                    {...(item.entity.value as ImageArticle)}
                                    onTagClicked={(query) =>
                                        onFilterChange('search', query)
                                    }
                                    query={getFilter('search')}
                                />
                            ))}
                        </div>
                        {articleMeta?.paging && (
                            <InfiniteScrollV2
                                paging={articleMeta.paging}
                                isLoading={isFetchingNextPage}
                                setPageNumber={handlePageChange}
                            />
                        )}
                    </Fragment>
                ) : isLoading ? null : (
                    <Alert
                        alertType="info"
                        message={t('noData')}
                        className="mb-5"
                    />
                )}
            </div>
        </DefaultPageLayout>
    );
};
