import { FC, ReactElement, useMemo } from 'react';
import { Press } from './Press';
import { SuspicionItemEntityType } from '_enums';
import { SearchEngine } from './SearchEngine';
import { Company } from './Company';
import { SocialMediaPost } from './SocialMediaPost';
import { useTargetIndicatorsQuery } from '../../../../../hooks/queries/useTargetIndicatorsQuery';
import { useParams } from 'react-router-dom';
import { HighlightChunks } from '../../../../../components/_atoms/Highlight/types';
import { ArticleEntityWithSuspicions } from '../../../../../types/SuspicionsByCategory';

interface ArticleContentProps {
    article: ArticleEntityWithSuspicions;
    highlightChunks?: HighlightChunks;
}

export const ArticleContent: FC<ArticleContentProps> = (
    props,
): ReactElement | null => {
    const { targetId } = useParams<{ targetId: string }>();

    const indicatorsWithTooltipQuery = useTargetIndicatorsQuery(
        targetId,
        props.article.value.id,
    );

    const { suspicionKeywords, tooltips } = useMemo(() => {
        const entries =
            indicatorsWithTooltipQuery.data
                ?.flatMap(({ indicators }) => indicators)
                .map(
                    ({ highlightText, tooltipForText }) =>
                        [highlightText, tooltipForText] as const,
                ) ?? [];

        const tooltips = new Map(entries);

        return {
            suspicionKeywords: Array.from(tooltips.keys()).sort(),
            tooltips,
        };
    }, [indicatorsWithTooltipQuery.data]);

    const buildProps = <T,>(article: T) => ({
        highlightChunks: {
            ...props.highlightChunks,
            suspicionKeywords,
        },
        tooltips,
        article,
    });

    const { value } = props.article;

    if (value.type === SuspicionItemEntityType.Press) {
        return <Press {...buildProps(value)} />;
    }

    if (value.type === SuspicionItemEntityType.GoogleResult) {
        return <SearchEngine {...buildProps(value)} />;
    }

    if (value.type === SuspicionItemEntityType.TargetCompany) {
        return <Company {...buildProps(value)} />;
    }

    if (value.type === SuspicionItemEntityType.SocialMediaPost) {
        return <SocialMediaPost {...buildProps(value)} />;
    }

    return null;
};
