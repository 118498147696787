import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { EntityIcon } from '_atoms';

import { EntityType } from '_types';

interface ResultCountersProps extends HTMLAttributes<HTMLDivElement> {
    count?: Record<string, number>;
}

type TileEntityType = EntityType | 'socialPost' | 'socialConnection';

const getEntityIconType = (tet: TileEntityType): EntityType =>
    ['SocialAccountPost', 'SocialAccountConnection'].includes(tet)
        ? 'social'
        : (tet.toLowerCase() as EntityType);

const entityTypeLabel: Record<string, string> = {
    SocialAccountPost: 'socialMediaPostSection',
    SocialAccountConnection: 'socialMediaConnectionSection',
    press: 'pressSection',
    TargetCompany: 'companySection',
    Image: 'pictureSection',
    WebPage: 'searchEngineSection',
};

export const ResultCounters: FC<ResultCountersProps> = ({
    count,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <div
            className="flex gap-4 justify-between mb-2 p-4 w-full bg-gray-100"
            {...props}
        >
            {count
                ? Object.keys(count).map((key) => (
                      <div
                          key={key}
                          className="flex flex-col justify-center gap-2 flex-1 text-center"
                      >
                          <div className="flex flex-col gap-1">
                              <EntityIcon
                                  type={getEntityIconType(
                                      key as TileEntityType,
                                  )}
                                  bgColor="bg-gray-100"
                                  iconColor="text-neutral-500"
                                  width="h-6"
                                  height="w-6"
                                  logoPadding="p-0"
                              />
                              <div className="text-sm text-gray-600 font-bold tracking-widest">
                                  {count[key]}
                              </div>
                          </div>
                          <div className="text-neutral-500 font-semibold">
                              {key in entityTypeLabel
                                  ? t(entityTypeLabel[key])
                                  : '?'}
                          </div>
                      </div>
                  ))
                : null}
        </div>
    );
};
