import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Paragraph } from '_atoms';
import { Alert, WorkflowError } from '_molecules';
import { SocialMediaPosts } from '_organisms';
import { ResultsProps } from '../Results';
import ShadowDragonLogo from '../../../images/shadow-dragon-logo.png';
import { useSocialMediaErrors } from './useSocialMediaErrors';
import { SocialMediaErrors } from './SocialMediaErrors';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';

export const SocialMedia: FC<ResultsProps> = ({
    accessToken,
    targetData,
    caseData,
    entityIndicators,
}: ResultsProps) => {
    const { t } = useTranslation();
    const socialMediaErrors = useSocialMediaErrors();

    return (
        <DefaultPageLayout
            title={t('rawData.socialMedia')}
            headerActions={
                <div className="absolute right-9 top-13.5 -translate-y-1/2 flex flex-col items-center text-center">
                    <Paragraph size="small" color="default">
                        {t('socialMedia.attribution')}
                    </Paragraph>
                    <Image
                        src={ShadowDragonLogo}
                        alt="shadow dragon logo"
                        wrapperClassName="w-24"
                    />
                </div>
            }
        >
            <div className="flex flex-col gap-4">
                {targetData?.workflowErrors?.length ? (
                    <WorkflowError
                        errors={targetData?.workflowErrors}
                        path="socialmedia"
                    />
                ) : null}
                {targetData?.status === 'HasInitialProfile' && (
                    <Alert
                        alertType="warning"
                        headline={t('profileDataNotFinal')}
                    />
                )}
                {Boolean(socialMediaErrors.size) && (
                    <SocialMediaErrors errors={socialMediaErrors} />
                )}
            </div>
            <SocialMediaPosts
                accessToken={accessToken}
                targetData={targetData}
                caseData={caseData}
                entityIndicators={entityIndicators}
            />
        </DefaultPageLayout>
    );
};
