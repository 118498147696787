import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ResultsProps } from '../Results';
import { Error, LoadingSpinner } from '_atoms';
import { Alert, WorkflowError } from '_molecules';

import { nonProdDataTestId } from '_utils';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import {
    toElasticFilter,
    toElasticQuery,
    useElasticSearchQuery,
} from '../../../hooks/useElasticSearchQuery';
import * as RawSearch from '_organisms/RawSearch';
import { buildHighlightChunks } from '../../../components/_atoms/Highlight/public-utils';
import { SuspicionDetails } from '../RiskAnalysisV2/SuspicionDetails';
import { buildIndicatorsMap } from '../AllDataSearch/enrichArticleWithIndicators';

type SearchFilters = {
    createdDate?: {
        from?: Date;
        to?: Date;
    };
    language?: string;
    exactMatch?: boolean;
    search?: string;
};

export const Press: FC<ResultsProps> = ({
    targetData,
    caseData,
    entityIndicators,
}: ResultsProps) => {
    const { t } = useTranslation();
    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const { getFilter, onFilterChange } =
        RawSearch.useRawFilters<SearchFilters>();

    const createdDate = getFilter('createdDate');

    const {
        data,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        isLoading,
        isFetching,
        isError,
    } = useElasticSearchQuery({
        params: {
            caseId,
            targetId,
            entityType: 'press',
            pageSize: 36,
            exactMatch: getFilter('exactMatch'),
            query: getFilter('search'),
        },
        body: {
            language: toElasticQuery(getFilter('language')),
            createdDate: toElasticFilter({
                gte: createdDate?.from,
                lte: createdDate?.to,
            }),
        },
    });

    const indicatorsById = useMemo(
        () => buildIndicatorsMap(entityIndicators),
        [entityIndicators],
    );

    const articles = useMemo(
        () =>
            (data?.pages.flatMap((page) => page.items) ?? []).map(
                (article) => ({
                    ...article,
                    indicators:
                        indicatorsById.get(article.entity.value.id) ?? [],
                }),
            ),
        [data, indicatorsById],
    );

    const articleMeta = data?.pages.at(-1);

    const handlePageChange = useCallback(() => {
        if (hasNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, fetchNextPage]);

    const [activeArticleId, setActiveArticleId] =
        useState<string | undefined>();

    const firstArticleId = articles?.at(0)?.entity.value.id;

    useEffect(() => {
        if (firstArticleId) {
            setActiveArticleId(firstArticleId);
        }
    }, [firstArticleId]);

    const selectedArticle = articles?.find(
        (article) => article.entity.value.id === activeArticleId,
    );

    const pagination = articleMeta?.paging
        ? {
              ...articleMeta?.paging,
              handlePageChange,
          }
        : undefined;

    const highlightChunks = buildHighlightChunks({
        searchQuery: getFilter('search'),
        target: targetData,
        caseKeywords: caseData?.keywords,
    });

    const languageOptions = useMemo(
        () => [
            {
                label: '-',
            },
            {
                label: t('filters.English'),
                value: 'English',
            },
            {
                label: t('filters.German'),
                value: 'German',
            },
        ],
        [t],
    );

    return (
        <DefaultPageLayout title={t('rawData.press')}>
            <RawSearch.SearchPanel
                onSearchChange={(value) => onFilterChange('search', value)}
                searchValue={getFilter('search')}
            >
                <RawSearch.Checkbox
                    value={Boolean(getFilter('exactMatch'))}
                    onChange={(value) => onFilterChange('exactMatch', value)}
                    label={t('exactSearch')}
                />
                <RawSearch.DateRange
                    value={getFilter('createdDate') ?? {}}
                    onChange={(value) => onFilterChange('createdDate', value)}
                />
                <RawSearch.Select
                    options={languageOptions}
                    onChange={(value) => onFilterChange('language', value)}
                    value={getFilter('language')}
                    title={t('filters.language')}
                />
            </RawSearch.SearchPanel>
            <div className="flex-1">
                <WorkflowError
                    errors={targetData?.workflowErrors}
                    path="press"
                />

                {targetData?.status === 'HasInitialProfile' && (
                    <Alert
                        alertType="warning"
                        headline={t('profileDataNotFinal')}
                        className="mb-2"
                    />
                )}

                <div
                    className="relative mt-6 flex flex-col gap-4"
                    data-testid={nonProdDataTestId('press')}
                >
                    {isLoading ? (
                        <div className="absolute top-0 flex justify-center w-full z-0">
                            <LoadingSpinner message={t('profileLoading')} />
                        </div>
                    ) : isError ? (
                        <Error
                            headline={t('profileErrorHeadline')}
                            message={t('profileErrorRetryMessage')}
                        />
                    ) : null}
                    {articles.length ? (
                        <SuspicionDetails
                            isLoading={isFetching || isFetchingNextPage}
                            articles={articles}
                            selectedArticle={selectedArticle}
                            selectArticle={setActiveArticleId}
                            pagination={pagination}
                            isNextPageLoading={isFetchingNextPage}
                            highlightChunks={highlightChunks}
                        />
                    ) : isLoading ? null : (
                        <Alert
                            alertType="info"
                            message={t('noData')}
                            className="mb-5"
                        />
                    )}
                </div>
            </div>
        </DefaultPageLayout>
    );
};
