import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ResultsProps } from '../Results';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import { ProfileInfo } from './ProfileInfo';
import { SuspicionFilters } from './SuspicionFilters';
import { SuspicionRisk } from './SuspicionRisk';
import {
    useAllSuspicionLabelsQuery,
    useSuspicionArticlesQuery,
    useSuspicionsByCategoryQuery,
} from '_queries';
import { TargetStatus } from '../../../services/dataService';
import { useParams } from 'react-router-dom';
import { OverlayWithSpinner } from '_atoms';
import { useFilters } from './useFilters';
import { TableEmpty } from '_organisms/Table/TableEmpty/TableEmpty';
import { SuspicionDetails } from './SuspicionDetails';
import { FaAngleLeft } from 'react-icons/fa';
import { SuspicionRiskLevel } from '_enums';
import classNames from 'classnames';
import { buildHighlightChunks } from '../../../components/_atoms/Highlight/public-utils';
import { RedFlags } from './RedFlags';

export const riskSections = [
    SuspicionRiskLevel.High,
    SuspicionRiskLevel.Medium,
    SuspicionRiskLevel.Low,
];

export const RiskAnalysisV2 = (props: ResultsProps): React.ReactElement => {
    const { targetData, targetImages, caseData, redFlags } = props;

    const { t } = useTranslation();
    const { targetId } = useParams<{ targetId: string }>();

    const { filters, setFilters } = useFilters();

    const suspicionsByCategoryQuery = useSuspicionsByCategoryQuery(
        targetId,
        filters,
        targetData?.status === TargetStatus.HasInitialProfile,
    );

    const allSuspicionsQuery = useAllSuspicionLabelsQuery(targetId);

    const hasSuspicions =
        suspicionsByCategoryQuery.data?.high.length ||
        suspicionsByCategoryQuery.data?.medium.length ||
        suspicionsByCategoryQuery.data?.low.length ||
        redFlags.length;

    const detailedViewOpened = Boolean(
        filters.risk && (filters.selectedSuspicionId || filters.redFlag),
    );

    const nonEmptyRiskLevels = riskSections.filter(
        (risk) =>
            (filters.risk === risk &&
                suspicionsByCategoryQuery.data?.[risk].length) ||
            (!filters.risk && suspicionsByCategoryQuery.data?.[risk].length) ||
            (risk === SuspicionRiskLevel.High && redFlags.length > 0),
    );

    const suspicionArticlesQuery = useSuspicionArticlesQuery({
        targetId,
        suspicionId: filters.selectedSuspicionId,
        risk: filters.risk,
        ...(filters.contentTypes && { contentTypes: filters.contentTypes }),
        ...(filters.reviewStatuses && {
            reviewStatuses: filters.reviewStatuses,
        }),
        ...(filters.sources && {
            sources: filters.sources,
        }),
    });

    const articles = useMemo(
        () => suspicionArticlesQuery.data?.pages.flatMap((page) => page.data),
        [suspicionArticlesQuery],
    );

    const articlePaging = suspicionArticlesQuery.data?.pages.at(-1)?.paging;

    const [activeArticleId, setActiveArticleId] =
        useState<string | undefined>();

    const firstArticleId = articles?.at(0)?.entity.value.id;

    useEffect(() => {
        if (firstArticleId) {
            setActiveArticleId(firstArticleId);
        }
    }, [firstArticleId]);

    const selectedArticle = articles?.find(
        (article) => article.entity.value.id === activeArticleId,
    );

    const selectedRedFlag = redFlags.find(
        (redFlag) => filters.redFlag === redFlag.type,
    );

    const highlightChunks = buildHighlightChunks({
        target: targetData,
        caseKeywords: caseData?.keywords,
    });

    const handlePageChange = useCallback(() => {
        if (suspicionArticlesQuery.hasNextPage) {
            suspicionArticlesQuery.fetchNextPage();
        }
    }, [suspicionArticlesQuery]);

    const pagination = articlePaging
        ? {
              ...articlePaging,
              handlePageChange,
          }
        : undefined;

    const targetFullName = [
        targetData?.firstname,
        targetData?.middlename,
        targetData?.lastname,
    ]
        .filter(Boolean)
        .join(' ');

    const displaySection = useCallback(
        (risk: SuspicionRiskLevel) => {
            if (!filters.selectedSuspicionId) {
                return true;
            }

            return filters.selectedSuspicionId && filters.risk === risk;
        },
        [filters],
    );

    return (
        <DefaultPageLayout
            title={t('riskAnalysis.title')}
            headerActions={
                <ProfileInfo
                    name={targetFullName}
                    createdAt={targetData?.profile?.createdAt}
                    imageUrl={targetImages[0]}
                />
            }
        >
            <div className="w-full h-1 border-t mb-5" />
            <button
                className={classNames(
                    'bg-transparent text-md flex gap-1 font-bold items-center text-primary-4 font-jost hover:text-primary-3 transition-colors',
                    !detailedViewOpened && 'hidden',
                )}
                onClick={() => {
                    setFilters({
                        risk: undefined,
                        selectedSuspicionId: undefined,
                    });
                }}
            >
                <FaAngleLeft />
                <div>{t('back')}</div>
            </button>
            <SuspicionFilters
                setFilters={setFilters}
                filters={filters}
                allSuspicions={allSuspicionsQuery.data ?? []}
            />
            <OverlayWithSpinner
                showOverlay={suspicionsByCategoryQuery.isFetching}
                hideLoader={true}
            >
                <div className="flex flex-col gap-7 mt-5">
                    {!!(suspicionsByCategoryQuery.isSuccess && hasSuspicions) &&
                        nonEmptyRiskLevels
                            .filter((risk) => displaySection(risk))
                            .map((risk) => (
                                <div key={risk} className="flex flex-col gap-5">
                                    <SuspicionRisk
                                        risk={risk}
                                        suspicions={
                                            suspicionsByCategoryQuery.data[risk]
                                        }
                                        setFilters={setFilters}
                                        filters={filters}
                                        redFlags={
                                            risk === SuspicionRiskLevel.High
                                                ? redFlags
                                                : undefined
                                        }
                                    />
                                    {Boolean(
                                        filters.risk === risk &&
                                            filters.selectedSuspicionId,
                                    ) && (
                                        <SuspicionDetails
                                            isLoading={
                                                suspicionArticlesQuery.isFetching &&
                                                !suspicionArticlesQuery.isFetchingNextPage
                                            }
                                            articles={articles ?? []}
                                            selectedArticle={selectedArticle}
                                            selectArticle={setActiveArticleId}
                                            pagination={pagination}
                                            isNextPageLoading={
                                                suspicionArticlesQuery.isFetchingNextPage
                                            }
                                            highlightChunks={highlightChunks}
                                        />
                                    )}
                                    {!!(
                                        filters.risk === risk && selectedRedFlag
                                    ) && <RedFlags redFlag={selectedRedFlag} />}
                                </div>
                            ))}
                    {suspicionsByCategoryQuery.isSuccess && !hasSuspicions && (
                        <TableEmpty
                            headline={t('riskAnalysis.notFound.headline')}
                            message={t('riskAnalysis.notFound.message')}
                        />
                    )}
                </div>
            </OverlayWithSpinner>
        </DefaultPageLayout>
    );
};
