import { FC } from 'react';
import classnames from 'classnames';

export const ImageAspectRatio = {
    none: 'aspect-h-none aspect-w-none',
    square: 'aspect-h-1 aspect-w-1',
    portrait: 'aspect-h-4 aspect-w-3',
    landscape: 'aspect-h-3 aspect-w-4',
} as const;

type ImageProps = {
    aspectRatio?: keyof typeof ImageAspectRatio;
    alt: string;
    className?: string;
    wrapperClassName?: string;
    src: string;
    onClick?: () => void;
};

export const Image: FC<ImageProps> = ({
    aspectRatio = 'none',
    alt,
    src,
    className,
    wrapperClassName,
    onClick,
}) => {
    return (
        <figure
            className={classnames(
                ImageAspectRatio[aspectRatio],
                wrapperClassName,
            )}
        >
            <img
                alt={alt}
                className={classnames(
                    'h-full w-full object-center object-cover',
                    className,
                )}
                src={src}
                onClick={onClick}
            />
        </figure>
    );
};
