import { FC } from 'react';
import { DynamicKeyword, FormSchema } from '../form.interface';
import { Headline } from '_atoms';
import { LocalizationService } from '@indicium/common';
import { FaInstagram } from 'react-icons/fa6';
import { AiOutlineLinkedin } from 'react-icons/ai';
import { LuGlobe2, LuLandmark } from 'react-icons/lu';
import { TbBrandFacebook, TbBrandX, TbMail, TbPhone } from 'react-icons/tb';
import { RiXingLine } from 'react-icons/ri';
import { FiGlobe, FiMapPin } from 'react-icons/fi';
import Flags, { FlagComponent } from 'country-flag-icons/react/1x1';
import { Locale } from '@indicium/common/src/types/Locale/Locale';
import i18n from 'i18next';
import {
    IconWrapper,
    LeftSideText,
    RightSideBigText,
    RightSideIconAndText,
    RightSideLightText,
    RightSideText,
    TARGET_SUMMARY_ICON_SIZE,
} from './HelperComponents';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { GiFemale, GiMale } from 'react-icons/gi';
import { MdOutlineCalendarMonth } from 'react-icons/md';

const getLocalizedCountryObject = (countryCode: string) => {
    const locale = i18n.resolvedLanguage;
    return LocalizationService.getCountry(countryCode, locale as Locale);
};
const getFlagAndNationalityForCountry = (countryCode: string) => {
    const country = getLocalizedCountryObject(countryCode);
    const Flag =
        country && ((Flags as any)[country.iso2] as FlagComponent | undefined);

    return {
        value: country?.nationality ?? countryCode,
        icon: Flag ? (
            <Flag
                className={classNames(
                    `w-[${TARGET_SUMMARY_ICON_SIZE}px] h-[${TARGET_SUMMARY_ICON_SIZE}px]`,
                    'rounded-full',
                )}
            />
        ) : undefined,
    };
};

export const PersonalDetails: FC<{ data: FormSchema['personalDetails'] }> = ({
    data,
}) => {
    const { t } = useTranslation();
    const fullName = [
        data.title && t('titles.' + data.title),
        data.firstName,
        data.middleName,
        data.lastName,
    ]
        .filter(Boolean)
        .join(' ');

    return (
        <div className="flex justify-between items-center">
            <Headline
                Level="h4"
                color="inherit"
                className="flex flex-wrap items-center gap-x-1 text-left"
            >
                {fullName}
                <span className="text-sm">
                    {data.gender === 'female' && (
                        <GiFemale size={TARGET_SUMMARY_ICON_SIZE} />
                    )}
                    {data.gender === 'male' && (
                        <GiMale size={TARGET_SUMMARY_ICON_SIZE} />
                    )}
                </span>
            </Headline>
        </div>
    );
};

export const BirthInfo: FC<{ data: FormSchema['birthInfo'] }> = ({ data }) => {
    let country = '';

    if (data.countryOfBirth) {
        const countryData = getLocalizedCountryObject(data.countryOfBirth);
        country = countryData?.name ?? data.countryOfBirth;
    }

    const fullPlaceOfBirth = [data.placeOfBirth, country]
        .filter(Boolean)
        .join(', ');

    const birthInfo = `${data.dateOfBirth} ${
        fullPlaceOfBirth !== '' ? i18n.t('in') : ''
    } ${fullPlaceOfBirth}`;

    return (
        <div className="flex items-start gap-2">
            <IconWrapper
                icon={MdOutlineCalendarMonth}
                size={TARGET_SUMMARY_ICON_SIZE}
            />
            <LeftSideText>{birthInfo}</LeftSideText>
        </div>
    );
};

export const ResidentialInfo: FC<{ data: FormSchema['residentialInfo'] }> = ({
    data,
}) => {
    let country = '';

    if (data.countryOfResidence) {
        const countryData = getLocalizedCountryObject(data.countryOfResidence);
        country = countryData?.name ?? data.countryOfResidence;
    }

    return (
        <div className="flex items-center gap-2">
            <IconWrapper icon={LuGlobe2} size={TARGET_SUMMARY_ICON_SIZE} />
            <LeftSideText>{country}</LeftSideText>
        </div>
    );
};

export const ContactInfoEmail: FC<{
    data: FormSchema['contactEmails'][number];
}> = ({ data: { email } }) => (
    <div className="flex flex-col gap-2">
        {email && (
            <div className="flex gap-2">
                <IconWrapper icon={TbMail} size={TARGET_SUMMARY_ICON_SIZE} />
                <LeftSideText>{email}</LeftSideText>
            </div>
        )}
    </div>
);

export const ContactInfoPhone: FC<{
    data: FormSchema['contactPhones'][number];
}> = ({ data: { phone } }) => (
    <div className="flex flex-col gap-2">
        {phone && (
            <div className="flex gap-2">
                <IconWrapper icon={TbPhone} size={TARGET_SUMMARY_ICON_SIZE} />
                <LeftSideText>{phone}</LeftSideText>
            </div>
        )}
    </div>
);

export const ContactInfoWebsite: FC<{
    data: FormSchema['contactWebsites'][number];
}> = ({ data: { website } }) => (
    <div className="flex flex-col gap-2">
        {website && (
            <div className="flex gap-2">
                <IconWrapper icon={FiGlobe} size={TARGET_SUMMARY_ICON_SIZE} />
                <LeftSideText>{website}</LeftSideText>
            </div>
        )}
    </div>
);

export const FacebookLinks: FC<{
    data: FormSchema['facebookLinks'][number];
}> = ({ data: { link } }) => (
    <div className="flex gap-2">
        <IconWrapper icon={TbBrandFacebook} size={TARGET_SUMMARY_ICON_SIZE} />
        <LeftSideText>{link}</LeftSideText>
    </div>
);

export const InstagramLinks: FC<{
    data: FormSchema['instagramLinks'][number];
}> = ({ data: { link } }) => (
    <div className="flex gap-2">
        <IconWrapper icon={FaInstagram} size={TARGET_SUMMARY_ICON_SIZE} />
        <LeftSideText>{link}</LeftSideText>
    </div>
);

export const TwitterLinks: FC<{
    data: FormSchema['twitterLinks'][number];
}> = ({ data: { link } }) => (
    <div className="flex gap-2">
        <IconWrapper icon={TbBrandX} size={TARGET_SUMMARY_ICON_SIZE} />
        <LeftSideText>{link}</LeftSideText>
    </div>
);
export const LinkedinLinks: FC<{
    data: FormSchema['linkedinLinks'][number];
}> = ({ data: { link } }) => (
    <div className="flex gap-2">
        <IconWrapper icon={AiOutlineLinkedin} size={TARGET_SUMMARY_ICON_SIZE} />
        <LeftSideText>{link}</LeftSideText>
    </div>
);
export const XingLinks: FC<{
    data: FormSchema['xingLinks'][number];
}> = ({ data: { link } }) => (
    <div className="flex gap-2">
        <IconWrapper icon={RiXingLine} size={TARGET_SUMMARY_ICON_SIZE} />
        <LeftSideText>{link}</LeftSideText>
    </div>
);

export const Nationality: FC<{ data: FormSchema['nationalities'][number] }> = ({
    data: { nationality },
}) => {
    const { icon, value } = getFlagAndNationalityForCountry(nationality);
    return (
        <div className="flex justify-between items-center gap-2">
            <div className="flex">
                {icon ? <span className="mr-2">{icon}</span> : null}
                <LeftSideText>{value}</LeftSideText>
            </div>
        </div>
    );
};

export const Job: FC<{ data: FormSchema['jobs'][number] }> = ({ data }) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col gap-0.25">
            <RightSideBigText>{data.companyName}</RightSideBigText>
            <RightSideLightText>{data.jobTitle}</RightSideLightText>
            {data.selfEmployed && (
                <RightSideLightText>{t('selfEmployed')}</RightSideLightText>
            )}

            {data.jobDate && (data.jobDate.start || data.jobDate.end) && (
                <RightSideIconAndText
                    Icon={MdOutlineCalendarMonth}
                    text={[data.jobDate?.start, data.jobDate?.end]
                        .filter(Boolean)
                        .join(' - ')}
                />
            )}

            {(data.companyCity || data.companyCountry) && (
                <RightSideIconAndText
                    Icon={FiMapPin}
                    text={[data.companyCity, data.companyCountry]
                        .filter(Boolean)
                        .join(', ')}
                />
            )}

            {data.companyWebsite && (
                <RightSideIconAndText
                    Icon={FiGlobe}
                    text={data.companyWebsite}
                />
            )}

            {data.companyCommercialRegisterNumber && (
                <RightSideIconAndText
                    text={data.companyCommercialRegisterNumber}
                    label={t('commercialRegisterNumber')}
                />
            )}

            {data.companyVatNumber && (
                <RightSideIconAndText
                    text={data.companyVatNumber}
                    label={t('companyVatNumber')}
                />
            )}
        </div>
    );
};

export const Education: FC<{ data: FormSchema['educationInfo'][number] }> = ({
    data,
}) => (
    <div>
        <RightSideBigText>{data.title}</RightSideBigText>
        <RightSideLightText>{data.type}</RightSideLightText>

        {data.date && (data.date.start || data.date.end) && (
            <RightSideIconAndText
                Icon={MdOutlineCalendarMonth}
                text={[data.date?.start, data.date?.end]
                    .filter(Boolean)
                    .join(' - ')}
            />
        )}

        {data.institutionName && (
            <RightSideIconAndText
                Icon={LuLandmark}
                text={data.institutionName}
            />
        )}

        {data.institutionLocation && (
            <RightSideIconAndText
                Icon={FiMapPin}
                text={data.institutionLocation}
            />
        )}
    </div>
);

export const DynamicKeywordContent: FC<{ data: DynamicKeyword }> = ({
    data,
}) => <LeftSideText>{data.value}</LeftSideText>;

export const Note: FC<{ data: FormSchema['note'] }> = ({ data }) => (
    <RightSideText>{data.value}</RightSideText>
);
