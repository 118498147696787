import { ArticleIndicator } from '../../../types/SuspicionsByCategory';
import { EntityIndicator } from '../../../hooks/queries/useTargetIndicatorsQuery';

export const buildIndicatorsMap = (
    indicators: EntityIndicator[],
): ReadonlyMap<string, ArticleIndicator[]> => {
    return new Map<string, ArticleIndicator[]>(
        indicators.map((entity) => [
            entity.entityId,
            entity.indicators.map((indicator) => ({
                indicator: {
                    value: indicator.highlightText,
                },
            })),
        ]),
    );
};
